import { styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISO4217 } from '@samsonvt/shared-types/orderLambda';
import { PartPriceV2 } from '@samsonvt/shared-types/partsTable';
import type { PartDetailsForm } from '../EditModal';
import AttributePriceField from './AttributePriceField';
import AttributeSwitch from './AttributeSwitch';
import { AttributeTextField, DescriptionAttributeTextField } from './AttributeTextField';

export type OverviewTabProps = PartDetailsForm & {
  setPartForm: React.Dispatch<React.SetStateAction<PartDetailsForm | undefined>>;
};

export default function OverviewTab({
  availableToOrder,
  isPor,
  setPartForm,
  displayName,
  description,
  priceV2
}: OverviewTabProps) {
  const [touched, setTouched] = useState(false);
  const [storedPriceV2, setStoredPriceV2] = useState<PartPriceV2[] | undefined>();

  useEffect(() => {
    // Check if any amount in the array is undefined
    if (priceV2 && priceV2.every((item) => item.amount !== undefined)) {
      setStoredPriceV2(priceV2);
    }
  }, [priceV2]);

  const handleAvailableToOrderChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    setPartForm((partForm) => ({
      ...partForm!,
      availableToOrder: value,
      isPor: !value ? false : partForm!.isPor,
      price: !value ? undefined : partForm!.price,
      priceV2: !value ? unsetPriceV2Prices(partForm!.priceV2) : storedPriceV2
    }));
    setTouched(true);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartForm((partDetailsForm) => ({
      ...partDetailsForm!,
      displayName: event.target.value
    }));
    setTouched(true);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPartForm((partDetailsForm) => ({
      ...partDetailsForm!,
      description: event.target.value
    }));
    setTouched(true);
  };

  const handlePriceOnRequestChange = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    setPartForm((partForm) => ({
      ...partForm!,
      isPor: value,
      price: value ? undefined : partForm!.price,
      priceV2: value || partForm!.price === 'por' ? unsetPriceV2Prices(partForm!.priceV2) : storedPriceV2
    }));
    setTouched(true);
  };

  const handlePriceV2Change = (currency: ISO4217) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? undefined : parseFloat(event.target.value)
    setPartForm((partForm) => ({
      ...partForm!,
      price: value,
      priceV2: partForm!.priceV2?.map((price) =>
        price.currency === currency
          ? ({
              ...price,
              amount: value
            } as PartPriceV2)
          : price
      )
    }));
    setTouched(true);
  };

  const unsetPriceV2Prices = (prices: PartPriceV2[] | undefined) =>
    prices?.map(
      // Set prices to undefined if POR is true
      (price) =>
        ({
          ...price,
          amount: undefined
        } as PartPriceV2)
    );

  const porDisabled = !availableToOrder;
  const priceInputDisabled = !availableToOrder || isPor;

  const nameError = touched && !displayName?.length ? 'Display name is required' : undefined;
  const displayNameValidation = {
    error: Boolean(nameError),
    helperText: nameError
  };

  return (
    <Column>
      <AttributeTextField
        label="Part display name"
        data-testid="display-name-field"
        value={displayName}
        onChange={handleNameChange}
        onBlur={() => setTouched(true)}
        {...displayNameValidation}
      />
      <DescriptionAttributeTextField
        label="Description"
        data-testid="description-field"
        value={description}
        onChange={handleDescriptionChange}
      />
      <AttributeSwitch
        label="Available to order"
        checked={availableToOrder}
        onChange={handleAvailableToOrderChange}
        data-testid="edit-parts-available-switch"
      />
      <AttributeSwitch
        label="Price On Request"
        disabled={porDisabled}
        checked={isPor}
        onChange={handlePriceOnRequestChange}
        data-testid="edit-parts-por-switch"
      />
      {priceV2?.map((priceBasedOnCurrency) => {
        const { currency, amount } = priceBasedOnCurrency;

        return (
          <AttributePriceField
            key={currency}
            label={`Price (${currency})`}
            disabled={priceInputDisabled}
            size="small"
            value={priceInputDisabled ? '' : amount}
            type="number"
            inputProps={{
              style: { textAlign: 'right' },
              min: 0.01,
              step: 0.01
            }}
            onChange={handlePriceV2Change(currency)}
            // Stop disallowed characters that HTMLInput accepts
            onKeyDown={(event) => ["e", "E", "+", "-"].includes(event.key) && event.preventDefault()}
            data-testid="edit-parts-price-input"
            preferredCurrency={currency}
          />
        );
      })}
    </Column>
  );
}

const Column = styled('div')`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
`;
